import { ENVIRONMENT_INITIALIZER, inject } from "@angular/core";
import { QueryClient } from "@tanstack/angular-query-experimental";
import {
   type PersistedClient,
   persistQueryClient,
   persistQueryClientRestore,
} from "@tanstack/query-persist-client-core";
import { del, get, set } from "idb-keyval";

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
export function createIdbPersister(idbValidKey: IDBValidKey = "limbleQuery") {
   const persister = {
      persistClient: async (client: PersistedClient) => {
         await set(idbValidKey, client);
      },
      restoreClient: async () => {
         return get(idbValidKey);
      },
      removeClient: async () => {
         await del(idbValidKey);
      },
   };
   return persister;
}

const persister = createIdbPersister();
export const provideQueryClientPersistence = () => {
   return {
      provide: ENVIRONMENT_INITIALIZER,
      multi: true,
      useValue() {
         const queryClient = inject(QueryClient);
         persistQueryClient({ queryClient, persister, maxAge: Infinity });
         persistQueryClientRestore({ queryClient, persister });
      },
   };
};
