import type { Provider } from "@angular/core";
import {
   providePmSuggestionsAssetDocumentsFromManageAssets,
   providePmSuggestionsAssetDocumentsFromMemory,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/asset-documents/asset-documents";
import {
   providePmSuggestionsAssetsFromManageAssets,
   providePmSuggestionsAssetsFromMemory,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/assets/pm-suggestions-assets";
import {
   providePmSuggestionsUserPreferencesFromManageUser,
   providePmSuggestionsUserPreferencesFromMemory,
} from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/entities/user-preferences/pm-suggestions-user-preferences";
import { providePmSuggestionsResourceFromMemory } from "src/app/tasks-analytics/predictive-maintenance/ai-pm-builder/pm-suggestions/resource/pm-suggestions-resource-providers";

export function providePmSuggestions(): Array<Provider> {
   return [
      // TODO(WRENCH-297): Swap to `providePmSuggestionsResourceFromHttp(),`
      providePmSuggestionsResourceFromMemory(),
      providePmSuggestionsAssetsFromManageAssets(),
      providePmSuggestionsUserPreferencesFromManageUser(),
      providePmSuggestionsAssetDocumentsFromManageAssets(),
   ];
}

export function providePmSuggestionsFromMemory(): Array<Provider> {
   return [
      providePmSuggestionsResourceFromMemory(),
      providePmSuggestionsAssetsFromMemory(),
      providePmSuggestionsUserPreferencesFromMemory(),
      providePmSuggestionsAssetDocumentsFromMemory(),
   ];
}
